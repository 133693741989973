import flask from "icons/solid/flask.svg";
import chessboard from "icons/solid/chess-board.svg";
import binoculars from "icons/solid/binoculars.svg";
import Button from "hardcover-ui/components/Button";
import Text from "hardcover-ui/components/Text";
import Icon from "hardcover-ui/components/Icon";
import scroll from "icons/solid/scroll.svg";
import trending from "icons/solid/chart-line-up.svg";
import wand from "icons/solid/wand-sparkles.svg";
import yearInBooks from "icons/solid/trophy-star.svg";
import readerSimilarityIcon from "icons/solid/diagram-venn.svg";
import { AskJulesIcon } from "components/svgs/jules/AskJulesIcon";
import chevronDown from "icons/solid/chevron-down.svg";
import { UserType } from "types";
import MenuItem from "../MenuItem";
// import home from "icons/solid/house-chimney.svg";
// import calendar from "icons/solid/calendar-star.svg";

const labStyles =
  "hover:bg-white dark:hover:bg-gray-900 p-1 rounded-md w-full text-sm font-semibold";

export default function DiscoverMenu({ user }: { user?: UserType }) {
  return (
    <>
      <div className="flex flex-col w-full h-full py-2.5 justify-between">
        <MenuItem
          variant="green"
          url={user?.id ? "/home" : "/home/all"}
          icon={scroll}
          text="Feed"
          description="Recent activity by friends"
        />
        <MenuItem
          variant="green"
          url="/trending"
          icon={trending}
          text="Trending books"
          description="Most popular right now"
        />
        {/* <MenuItem
          variant="green"
          url="/calendar"
          icon={calendar}
          text="Release Calendar"
          description="Recent & upcoming books"
        /> */}
        <MenuItem
          variant="green"
          url="/recommendations"
          icon={wand}
          text="Recommendations"
          description="Just for you"
        />
        <MenuItem
          variant="green"
          url="/askjules"
          icon={AskJulesIcon}
          text="AskJules"
          description="Talk to our AI Librarian"
        />
      </div>
      <div className="group/lab relative bg-gray-100 dark:bg-gray-800 p-2 ">
        <div className="w-full flex justify-between items-center p-1">
          <div className="flex gap-2 py-1">
            <Icon icon={flask} className="text-gray-500" />
            <Text className="text-sm font-semibold text-gray-300">
              4 Lab Experiments
            </Text>
          </div>
          <Icon
            icon={chevronDown}
            size="sm"
            className="transform group-hover/lab:rotate-180 transition-transform duration-150 ease-out group-hover/lab:ease-in"
          />
        </div>
        <div className="hidden group-hover/lab:block translate-y-1 group-hover/lab:translate-y-0 transition-all ease-in-out duration-200 text-secondary-foreground">
          <Button
            className={labStyles}
            as={{
              element: "link",
              props: {
                href: "/bookle/all",
              },
            }}
          >
            <Icon icon={chessboard} className="text-secondary-foreground" />
            Bookle <span className="text-sm opacity-50">Guess the book</span>
          </Button>
          <Button
            className={labStyles}
            as={{
              element: "link",
              props: {
                href: "/trackers/series",
              },
            }}
          >
            <Icon icon={binoculars} className="text-secondary-foreground" />
            Series Tracker{" "}
            <span className="text-sm opacity-50">Save your place</span>
          </Button>
          <Button
            className={labStyles}
            as={{
              element: "link",
              props: {
                href: "/2023",
              },
            }}
          >
            <Icon icon={yearInBooks} className="text-secondary-foreground" />
            2023 Year in Books{" "}
            <span className="text-sm opacity-50">The hits</span>
          </Button>
          <Button
            className={labStyles}
            as={{
              element: "link",
              props: {
                href: "/similarity",
              },
            }}
          >
            <Icon
              icon={readerSimilarityIcon}
              className="text-secondary-foreground"
            />
            Reader Similarity{" "}
            <span className="text-sm opacity-50">&amp; Differences</span>
          </Button>
        </div>
      </div>
    </>
  );
}
